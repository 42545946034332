import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Row,
  Col,
  NavLink } from 'reactstrap';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import CircularProgressbar from 'react-circular-progressbar';
import moment from 'moment';
import Loader from 'react-loader-spinner'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import kcLogo from '../images/kc1.png';
import '../index.css';
import 'react-circular-progressbar/dist/styles.css';

class UrbinDetails extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  dateFormatter(cell: any) {
    if (!cell) {
      return "";
    }
    return `${moment(cell).format("DD-MM-YYYY, h:mm:ss a")? moment(cell).format("DD-MM-YYYY, h:mm:ss a"):moment(cell).format("DD-MM-YYYY, h:mm:ss a") }`;
  }
  
  handleSubmit() {
    alert("Pickup Scheduled");
  }
  
  kgFormatter(cell, row) {
    return (<div>{cell} kg</div>);
  }
  
  percentFormatter(cell, row) {
    return (<div>{cell} %</div>);
  }
  
  renderFillPercentStyle(point) {
    console.log(point.fill_percentage);
    if (point.working == true) {
      if (Number(point.fill_percentage) >= 0 && Number(point.fill_percentage) <= 40) {
        return "fill-percentage-green"
      } else if (Number(point.fill_percentage) > 40 && Number(point.fill_percentage) <= 80) {
        console.log('orange');
          return "fill-percentage-orange"
      } else {
          console.log('red');
          return "fill-percentage-red"
      }
    } else {
        console.log('gray');
      return "fill-percentage-green"
    }
  }
  
  render() {
    const { binDetail } = this.props;
    console.log(binDetail);
    return (
      <div>
        {/* Navbar */}
        <Navbar className="navbarStyle" light expand="md">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">Dashboard</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Urbin">Urbin</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Pickups">Pickups</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Kabadiwalla">Kabadiwalla</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {
          this.props.loading ? (
            <div className="spinnerContainer">
              <Loader
                type="TailSpin"
                color="#39A300"
                height="40"
                width="40"
                style={{ }}
              />
            </div>
          ) :

          (
            <div>
              <Row className="divCenter">
                <div className="profile__heading-binName-container">
                  <div className="profile__heading-binName">
                    {binDetail.bin_id} - {binDetail.name}
                  </div>
                  <div style={{ color: '#575757', fontFamily: 'Source Sans Pro', fontSize: 16 }}>{binDetail.address}</div>
                </div>

                <div className="button-fillPercent">
                  <button className="button-style" onClick={() => this.handleSubmit()}>Schedule</button>
                </div>
              </Row>

              <Row className="urbinDetails__container">
                <Col md={8}>
                  <div className="profile-container">
                    <div className="urbinDetails__profile">Profile</div>
                    <div className="urbinDetails__label">Primary Contact Name: <span className="urbinDetails__values">{binDetail.contact_name}</span></div>
                    <div className="urbinDetails__label">Primary Contact Mobile: <span className="urbinDetails__values">{binDetail.contact_number}</span></div>
                    <div className="urbinDetails__label">Number Of Residents: <span className="urbinDetails__values">{binDetail.number_of_residents}</span></div>
                    <div className="urbinDetails__label">Potential Volume: <span className="urbinDetails__values">{binDetail.potential_volume}</span></div>
                    <div className="urbinDetails__label">Assigned L1: <span className="urbinDetails__values">{binDetail.kw_name}</span></div>
                    <div className="urbinDetails__label">Assigned L1 Mobile: <span className="urbinDetails__values">{binDetail.kw_phone}</span></div>
                    <div className="urbinDetails__label">Date of Deployment: <span className="urbinDetails__values">{moment(binDetail.added_on).format('MMMM Do YYYY')}</span></div>
                    <div className="urbinDetails__label">Current Fill Level: <span className="urbinDetails__values">{binDetail.fill_level}%</span></div>
                  </div>
                </Col>
                <Col md={4}>
                  <Col md={12}>
                    <div className="box-container">
                      <div className="urbinDetails__label-box">Total Volume Recovered</div>
                      <div className="urbinDetails__values-box">{binDetail.total_weight} Kg</div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="box-container">
                      <div className="urbinDetails__label-box">Total Pickups</div>
                      <div className="urbinDetails__values-box">{binDetail.pickups}</div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="box-container">
                      <div className="urbinDetails__label-box">Number Of On Time Pickups</div>
                      <div className="urbinDetails__values-box">{binDetail.on_time}%</div>
                    </div>
                  </Col>
                </Col>
                {/*<Col md={4}>
                  <div className="image-container">
                    <img src={binDetail.picture} alt="Bin" height={window.innerHeight - 350} width={null} />
                  </div>
                </Col>*/}
              </Row>
              {/*<div className="tableContent">
                <div style={{ fontSize: 18, fontFamily: 'Source Sans Pro', fontWeight: 600, marginTop: 25, marginBottom: 10 }}>Last 5 Pickup Details</div>
                <BootstrapTable data={binDetail.last_pickups} hover multiColumnSearch>
                  <TableHeaderColumn dataField='collected_on' dataFormat={this.dateFormatter} isKey dataSort width="155" dataAlign='center' >Date and Time</TableHeaderColumn>
                  <TableHeaderColumn dataField='total_weight' dataSort width="145" dataAlign='center' dataFormat={this.kgFormatter}>Volume Collected</TableHeaderColumn>
                  <TableHeaderColumn dataField='quality' dataSort width="145" dataAlign='center' suffix='%' dataFormat={this.percentFormatter}>Quality</TableHeaderColumn>
                </BootstrapTable>
              </div>*/}
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    binDetail: state.urbin.binDetail,
    loading: state.urbin.loading
  };
};

export default connect (mapStateToProps, {
  
})(UrbinDetails);

