const INITIAL_STATE = {
  loading: false,
  bins: [],
  selectedBin: '',
  binDetail: []
}

export default (state = INITIAL_STATE, action) => {
  console.log(action);
  switch (action.type) {
    case 'BIN_DETAILS':
      return { ...state, loading: true };
    case 'BIN_DETAILS_SUCCESS':
      return { ...state, bins: action.payload, loading: false };
    case 'BIN_DETAILS_FAIL':
      return { ...state, loading: false };
    
    case 'SELECTED_MARKER':
      return { ...state, selectedBin: action.payload };
    
    case 'BIN_FULL_DETAILS':
      return { ...state, loading: true };
    case 'BIN_FULL_DETAILS_SUCCESS':
      return { ...state, binDetail: action.payload, loading: false };
    case 'BIN_FULL_DETAILS_FAIL':
      return { ...state, loading: false };
        
    default:
      return state;
  }
};
