//Production
// const url = { baseURL: 'https://api.recykle.in/api/' };

//Staging
// const url = { baseURL: 'https://africwaste-api.kabadiwalla.xyz/api/' };

// Dev
const url = { 
  baseURL: 'https://urbin-api.kabadiwallaconnect.in/public/',
  //baseURL: 'http://localhost:8080/',
  oldURL:  'http://iot.kabadiwallaconnect.in/api/v1/'
};


//Local
// const url = { baseURL: 'http://192.168.1.11:8000/api/' };

//Dummy
// const url = { baseURL: 'https://kc-exchange-api.kcdev.xyz/api/' };

export default url;
