const INITIAL_STATE = {
  loading: false,
  dashboardDetails: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DASHBOARD_DETAILS':
      return { ...state, loading: true };
    case 'DASHBOARD_DETAILS_SUCCESS':
      return { ...state, dashboardDetails: action.payload, loading: false };
    case 'DASHBOARD_DETAILS_FAIL':
      return { ...state, loading: false };
    
    default:
      return state;
  }
};
