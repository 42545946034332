import axios from 'axios';
import url from '../components/common/BaseURL'

const baseURL = url.baseURL;

export const getKabadiwallaDetails = () => {
  return (dispatch) => {
    dispatch({ type: 'KABADIWALLA' });
    axios.get(baseURL + 'kabadiwallas', {
    }).then(response => {
      dispatch({ type: 'KABADIWALLA_SUCCESS', payload: response.data });
    }).catch(err => {
      dispatch({ type: 'KABADIWALLA_FAIL' });
    });
  };
};

export const getDetails = (row) => {
  return (dispatch) => {
    dispatch({ type: 'KABADIWALLA_DETAILS' });
    axios.get(baseURL + 'kw/' + row.user_id, {
      params: {
        token: 'dashboardapinkadf91nksdnf91'
      }
    }).then(response => {
      dispatch({ type: 'KABADIWALLA_DETAILS_SUCCESS', payload: response.data.data });
    }).catch(err => {
      dispatch({ type: 'KABADIWALLA_DETAILS_FAIL' });
    });
  };
};
