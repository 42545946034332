import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { hashHistory } from 'react-router';
import kcLogo from '../images/kc1.png';
import '../index.css';
import { getPickupDetails, selectedPickupDetails, binIdChange, SchedulePickup, getPickupFullDetails } from '../actions';

class Pickups extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  
  componentDidMount() {
    this.props.getPickupDetails();
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  onClick = (row) => {
    console.log(this.props);
    this.props.getPickupFullDetails(row);
    hashHistory.push('/Pickup/Details');
  }
  
  dateFormatter(cell: any) {
    if (!cell) {
      return "";
    }
    return `${moment(cell).format("DD-MM-YYYY")? moment(cell).format("DD-MM-YYYY"):moment(cell).format("DD-MM-YYYY") }`;
  }
  
  handleChange(event) {
    const val = event.target.value
    if (val.length < 7) {
      this.props.binIdChange(val);
    }
  }
  
  handleSubmit() {
    const { binId } = this.props;
    console.log(binId);
    this.props.SchedulePickup(binId);
  }

  render() {
    const { pickups } = this.props;
    console.log(pickups);
    var options = {
     onRowClick: this.onClick,
     defaultSortName: 'scheduled_on', 
     defaultSortOrder: 'desc' 
    }
    return (
      <div>
        {/* Navbar */}
        <Navbar className="navbarStyle" light expand="md">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">Dashboard</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Urbin">Urbin</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Pickups">Pickups</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Kabadiwalla">Kabadiwalla</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <div className="urbinContainer tableContent">  
          <h2>Pickups</h2>
          <form>
            <label>
              <input placeholder="Bin Id" className="input-style" type="text" value={this.props.binId} onChange={(event) => this.handleChange(event)}  />
            </label>
            {/* <input className="button-style" type="submit" value="Schedule" /> */}
            {/* <button className="button-style">Schedule</button> */}
            <input className="button-style" type="button" onClick={() => this.handleSubmit()} value="Submit" />
          </form>
          {
            pickups ? (
              <div>
                {
                  <BootstrapTable data={pickups} pagination hover multiColumnSearch options={options}>
                    <TableHeaderColumn dataField='id' isKey dataSort width="100" dataAlign='center' >Pickup ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='name' width="145" dataAlign='center' >Location</TableHeaderColumn>
                    <TableHeaderColumn dataField='scheduled_on' dataFormat={this.dateFormatter} width="145" dataAlign='center' >Scheduled Time</TableHeaderColumn>
                    <TableHeaderColumn dataField='status' width="110" dataAlign='center'>Status</TableHeaderColumn>
                    <TableHeaderColumn dataField='kw_name' width="110" dataAlign='center'>Kabadiwalla</TableHeaderColumn>
                    <TableHeaderColumn dataField='total_weight' width="110" dataAlign='center'>Volume</TableHeaderColumn>
                  </BootstrapTable>
              }
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pickups: state.pickup.pickups,
    binId: state.pickup.binId,
    pickupScheduled: state.pickup.pickupScheduled
  };
};

export default connect (mapStateToProps, {
  getPickupDetails,
  selectedPickupDetails,
  binIdChange,
  SchedulePickup,
  getPickupFullDetails
})(Pickups);

