const INITIAL_STATE = {
  loading: false,
  kabadiwallaDetails: '',
  kw: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'KABADIWALLA':
      return { ...state, loading: true };
    case 'KABADIWALLA_SUCCESS':
      return { ...state, kabadiwallaDetails: action.payload, loading: false };
    case 'KABADIWALLA_FAIL':
      return { ...state, loading: false };
      
    case 'KABADIWALLA_DETAILS':
      return { ...state, loading: true };
    case 'KABADIWALLA_DETAILS_SUCCESS':
      return { ...state, kw: action.payload, loading: false };
    case 'KABADIWALLA_DETAILS_FAIL':
      return { ...state, loading: false };

    default:
      return state;
  }
};
