import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import kcLogo from '../images/kc1.png';
import '../index.css';

class KabadiwallaDetails extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  renderColor(status) {
    if(status === 'Completed') {
      return "fillPercentContainerCompleted";
    } else if (status === 'Scheduled') {
      return "fillPercentContainerScheduled"
    } else {
      return "fillPercentContainerPending"
    }
  }
  
  render() {
    const { kw } = this.props;
    return (
      <div>
      {/* Navbar */}
        <Navbar className="navbarStyle" light expand="md">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">Dashboard</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Urbin">Urbin</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Pickups">Pickups</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Kabadiwalla">Kabadiwalla</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <div className="urbinContainer">
          <div class="kwDetailsBarStyle">
            <div style={{ height: 50, width: null, backgroundColor: '#44B307', color: 'white', fontSize: 30, fontFamily: 'Source Sans Pro', paddingLeft: 40, paddingTop: 3, borderTopRightRadius: 10, borderTopLeftRadius: 10 }}>
              {kw.name}
            </div>
            <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20, paddingBottom: 20 }}>
              <div style={{ fontSize: 16, fontFamily: 'Source Sans Pro', color: '#44B307', marginBottom: 15 }}>
                User Id: 
                <div style={{ fontSize: 22, fontFamily: 'Source Sans Pro', color: '#4f4f4f', fontWeight: '600' }}>{  ' ' + kw.user_id}</div>
              </div>
              <div style={{ fontSize: 16, fontFamily: 'Source Sans Pro', color: '#44B307', marginBottom: 15 }}>
                Shop Name: 
                <div style={{ fontSize: 20, fontFamily: 'Source Sans Pro', color: '#4f4f4f', fontWeight: '600' }}>{ ' ' + kw.shop_name}</div>
              </div>
              <div style={{ fontSize: 16, fontFamily: 'Source Sans Pro', color: '#44B307', marginBottom: 15 }}>
                Email Id: 
                <div style={{ fontSize: 20, fontFamily: 'Source Sans Pro', color: '#4f4f4f', fontWeight: '600' }}>{ ' ' + kw.email}</div>
              </div>
              <div style={{ fontSize: 16, fontFamily: 'Source Sans Pro', color: '#44B307', marginBottom: 15 }}>
                Total Pickups Completed: 
                <div style={{ fontSize: 20, fontFamily: 'Source Sans Pro', color: '#4f4f4f', fontWeight: '600' }}>{ ' ' + kw.total_completed_pickups}</div>
              </div>
              <div style={{ fontSize: 16, fontFamily: 'Source Sans Pro', color: '#44B307', marginBottom: 15 }}>
                Total Volume Pickedup: 
                <div style={{ fontSize: 20, fontFamily: 'Source Sans Pro', color: '#4f4f4f', fontWeight: '600' }}>{ ' ' + Math.round(kw.total_collected_kg)} Kg</div>
              </div>
              <div style={{ fontSize: 16, fontFamily: 'Source Sans Pro', color: '#44B307', marginBottom: 15 }}>
                Overall Quality: 
                <div style={{ fontSize: 20, fontFamily: 'Source Sans Pro', color: '#4f4f4f', fontWeight: '600' }}>{ ' ' + Math.round(kw.average_quality_in_percentage)}%</div>
              </div>
            </div>
          </div>
        </div>    
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    kw: state.kabadiwalla.kw
  };
};

export default connect (mapStateToProps, {
  
})(KabadiwallaDetails);
