import axios from 'axios';
import url from '../components/common/BaseURL'

const baseURL = url.baseURL;

// Get bin details
export const getPickupDetails = () => {
  return (dispatch) => {
    dispatch({ type: 'PICKUP' });
    axios.get(baseURL + 'pickups', {}).then(response => {
      dispatch({ type: 'PICKUP_SUCCESS', payload: response.data });
    }).catch(err => {
      dispatch({ type: 'PICKUP_FAIL' });
    });
  };
};

export const getPickupFullDetails = (pickup) => {
  return (dispatch) => {
    dispatch({ type: 'PICKUP_FULL' });
    axios.get(baseURL + 'pickups/details', {
      params:{
        id: pickup.id
      }
    }).then(response => {
      dispatch({ type: 'PICKUP_FULL_SUCCESS', payload: response.data });
    }).catch(err => {
      dispatch({ type: 'PICKUP_FULL_FAIL' });
    });
  };
};

// Schedule Pickup
export const SchedulePickup = (binId) => {
  return (dispatch) => {
    console.log('binId', binId);
    dispatch({ type: 'SCHEDULE_PICKUP' });
    axios.post(url.oldURL + 'kw/pickup/', {
      token: 'asdfasnf9nasfuh*()239',
      bin_id: binId
    }).then(response => {
      dispatch({ type: 'SCHEDULE_PICKUP_SUCCESS' });
      console.log(response);
      alert("Pickup Scheduled");
    }).catch(err => {
      console.log(err);
      dispatch({ type: 'SCHEDULE_PICKUP_FAIL' });
    });
  };
};

export const selectedPickupDetails = (row) => {
  return {
    type: 'SELECTED_PICKUP_DETAILS',
    payload: row
  }
}

export const binIdChange = (text) => {
  return {
    type: 'BIN_ID',
    payload: text
  }
} 