import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import googleMapStyle from './common/googleMapStyle';
import {Map, InfoWindow, Marker, GoogleApiWrapper, google} from 'google-maps-react';
import Loader from 'react-loader-spinner';
import kcLogo from '../images/kc1.png';
import '../index.css';
import grayMarker from '../images/grayMarker.svg';
import greenMarker from '../images/greenMarker.svg';
import orangeMarker from '../images/orangeMarker.svg';
import redMarker from '../images/redMarker.svg';
import blueCircle from '../images/blueCircle.svg';

import { binDetails, selectedMarker, binFullDetails } from '../actions';

class Urbin extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      showingInfoWindow: false,
      selectedPlace: {}
    };
  }
  
  componentDidMount() {
    this.props.binDetails();
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  onMarkerClick(marker) {
    console.log('marker', marker);
    // marker = JSON.parse(props.title);
    this.props.binFullDetails(marker);
    hashHistory.push('/Urbin/Details');
  }
  
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  
  renderMarker(point) {
    if (point.type === "L1") {
      return {icon: blueCircle }
    } else {
        if (Number(point.fill_percentage) >= 0 && Number(point.fill_percentage) <= 40) {
          return {icon: greenMarker }
        } else if (Number(point.fill_percentage) > 40 && Number(point.fill_percentage) <= 80) {
          return {icon: orangeMarker }
        } else {
          return {icon: redMarker }
        }
    }
  }
  
  mapMarkerRender() {
    const { bins } = this.props;
    const Kabadiwallas = [
      {name: 'Thirumani', latitude: '13.025182', longitude: '80.270872', type: 'L1'},
      {name: 'Thirumani - Warren Road', latitude: '13.03135', longitude: '80.260236', type: 'L1'},
      {name: 'Santhanam', latitude: '13.029456', longitude: '80.263902', type: 'L1'}
    ];
    if (bins.length !== 0) {
      Kabadiwallas.map((Kabadiwalla) => (
        bins.push(Kabadiwalla)
      ))
      return (
        bins.map((point,index) => (
          <Marker
            key={index}
            title={point.name}
            name={point.bin_id}
            position={{lat: Number(point.latitude), lng: Number(point.longitude)}} 
            options={this.renderMarker(point)}
            onClick={point.type !== "L1" ? this.onMarkerClick.bind(this, point) : null}
          >
            <InfoWindow onClose={this.onInfoWindowClose}>
                <div>
                  <h1>{point.name}</h1>
                </div>
            </InfoWindow>
          </Marker>
        ))
      )
    } 
  }
  
  render() {
    return (
      <div >
        {/* Navbar */}
        <Navbar className="navbarStyle" light expand="md">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">Dashboard</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Urbin">Urbin</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Pickups">Pickups</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Kabadiwalla">Kabadiwalla</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {
          this.props.loading ? (
            <div className="spinnerContainer">
              <Loader
                type="TailSpin"
                color="#39A300"
                height="40"
                width="40"
                style={{ }}
              />
            </div>
          ) : (
            <div className="urbinContainer">
              {
                this.props.bins ? (
                  <div style={{ height: null, width: 'auto', position: 'relative', marginTop: 15, marginLeft: 0, marginRight: 0 }}>
                    <Map 
                      google={this.props.google}
                      streetViewControl= {false}
                      scrollwheel={true}
                      zoom={14} 
                      minZoom={12.5}
                      onClick={this.onMapClicked}
                      styles={googleMapStyle}
                      style={{ height: window.innerHeight - 120, width: '100%' }}
                      // style={{ height: window.innerHeight - 220, width: '100%' }}
                      mapTypeControl={false}  
                      initialCenter={{
                        lat: 13.03135, 
                        lng: 80.260236
                      }}
                    >
                      {this.mapMarkerRender()}
                    </Map>
                  </div>
                ) : null
              }
            </div>
          )
        }  
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bins: state.urbin.bins,
    loading: state.urbin.loading
  };
};

const WrappedContainer = GoogleApiWrapper({
  apiKey: ('AIzaSyAg7vS8VhWiqP_wZoC2-SLRZyinttgjzaY')
})(Urbin);

export default connect (mapStateToProps, {
  binDetails,
  selectedMarker,
  binFullDetails
})(WrappedContainer);
