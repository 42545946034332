import { combineReducers } from 'redux';
import UrbinReducer from './UrbinReducer';
import DashboardReducer from './DashboardReducer';
import PickupReducer from './PickupReducer';
import KabadiwallaReducer from './KabadiwallaReducer';

export default combineReducers({
  urbin: UrbinReducer,
  dashboard: DashboardReducer,
  pickup: PickupReducer,
  kabadiwalla: KabadiwallaReducer
});