import React, { Component } from 'react';
import { Router, Route, hashHistory } from 'react-router';
import Dashboard from './components/Dashboard';
import Urbin from './components/Urbin';
import UrbinDetails from './components/UrbinDetails';
import Pickups from './components/Pickups';
import PickupDetails from './components/PickupDetails';
import Kabadiwalla from './components/Kabadiwalla';
import KabadiwallaDetails from './components/KabadiwallaDetails';

export default class Routes extends Component {
    render() {
        return (
            <Router history={hashHistory}>
              <Route path="/" component={Dashboard} />
              <Route path="/Urbin" component={Urbin} />
              <Route path="/Pickups" component={Pickups} />
              <Route path="/Kabadiwalla" component={Kabadiwalla} />
              <Route path="/Kabadiwalla/Details" component={KabadiwallaDetails} />
              <Route path="/Urbin/Details" component={UrbinDetails} />
              <Route path="/Pickup/Details" component={PickupDetails} />
            </Router>
        );
    }
}
