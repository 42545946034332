import axios from 'axios';
import url from '../components/common/BaseURL'

const baseURL = url.baseURL;

// Get bin details
export const binDetails = () => {
  return (dispatch) => {
    dispatch({ type: 'BIN_DETAILS' });
    axios.get(baseURL + 'bins', {
    }).then(response => {
      console.log(response);
      dispatch({ type: 'BIN_DETAILS_SUCCESS', payload: response.data });
    }).catch(err => {
      dispatch({ type: 'BIN_DETAILS_FAIL' });
    });
  };
};

export const selectedMarker = (marker) => {
  return {
    type: "SELECTED_MARKER",
    payload: marker
  };
};


export const binFullDetails = (bin) => {
  return (dispatch) => {
    dispatch({ type: 'BIN_FULL_DETAILS' });
    console.log(bin.id);
    axios.get(baseURL + 'bins/details', {
      params:{
        id: bin.id
      }
    }).then(response => {
      console.log(response);
      dispatch({ type: 'BIN_FULL_DETAILS_SUCCESS', payload: response.data });
    }).catch(err => {
      dispatch({ type: 'BIN_FULL_DETAILS_FAIL' });
    });
  };
};

