import axios from 'axios';
import url from '../components/common/BaseURL'

const baseURL = url.baseURL;

// Get bin details
export const getDashboardDetails = () => {
  return (dispatch) => {
    dispatch({ type: 'DASHBOARD_DETAILS' });
    axios.get(url.oldURL + 'urbin/dashboard/', {
      params: {
        token: 'dashboardapinkadf91nksdnf91'
      }
    }).then(response => {
      dispatch({ type: 'DASHBOARD_DETAILS_SUCCESS', payload: response.data.data });
    }).catch(err => {
      dispatch({ type: 'DASHBOARD_DETAILS_FAIL' });
    });
  };
};