import React, { Component } from 'react';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import Router from './routes';
import './index.css';

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState)
  } catch(e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState == null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined
  }
}

export default class App extends Component {

  constructor() {
    super();
    const persistedStorage = loadFromLocalStorage();
    this.store = createStore(reducers, persistedStorage, applyMiddleware(ReduxThunk));
    this.store.subscribe(() => saveToLocalStorage(this.store.getState()))
  }
  

  render() {
    return (
      <Provider store={this.store}>
        <Router />
      </Provider>
    );
  }
}
