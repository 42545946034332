import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import kcLogo from '../images/kc1.png';
import { getDashboardDetails } from '../actions';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  
  componentDidMount() {
    this.props.getDashboardDetails();
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  dateFormatter () {
    const { dashboardDetails } = this.props;
    const date=[];
    for (var i = 0; i < dashboardDetails.volume_and_quality.x_axis.length; i++) {
      const dateValue = moment(dashboardDetails.volume_and_quality.x_axis[i]).format("MMM Do");
      date.push(dateValue);
    }
    return date;
  }
  
  render() {
    const { dashboardDetails } = this.props;
    return (
      <div>
      {/* Navbar */}
        <Navbar className="navbarStyle" light expand="md">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">Dashboard</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Urbin">Urbin</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Pickups">Pickups</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Kabadiwalla">Kabadiwalla</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <div className="urbinContainer">
          <h2>Dashboard</h2>
          <div style={{ marginLeft: 5, marginRight: 5, marginTop: 20, marginBottom: 80 }} class="row justify-content-between">
            <div class="test col-xs-10 col-sm-2" style={{ backgroundColor: '#F86C6B'}}>
              <div style={{ fontSize: 14, fontFamily: 'Source Sans Pro', color: '#fff', paddingTop: 10 }}>Total Pickups Scheduled</div>
              <h4>{this.props.dashboardDetails.total_pickups}</h4>
            </div>
            <div class="test col-xs-10 col-sm-2" style={{ backgroundColor: '#21A8D8' }}>
              <div style={{ fontSize: 14, fontFamily: 'Source Sans Pro', color: '#fff', paddingTop: 10 }}>Total Pickups Completed</div>
              <h4>{this.props.dashboardDetails.completed_pickups}</h4>
            </div>
            <div class="test col-xs-10 col-sm-2" style={{ backgroundColor: '#4EBD74' }}>
              <div style={{ fontSize: 14, fontFamily: 'Source Sans Pro', color: '#fff', paddingTop: 10 }}>Overall Volume</div>
              <h4>{this.props.dashboardDetails.total_collected_kg} Kg</h4>
            </div>
            <div class="test col-xs-10 col-sm-2" style={{ backgroundColor: '#F8CA05' }}>
              <div style={{ fontSize: 14, fontFamily: 'Source Sans Pro', color: '#fff', paddingTop: 10 }}>Overall Quality</div>
              <h4>{Math.round(this.props.dashboardDetails.overall_quality_in_percentage)}%</h4>
            </div>
          </div>
          {
            dashboardDetails.volume_and_quality ? (
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div style={{ fontSize: 18, fontFamily: 'Source Sans Pro', fontWeight: 600, margin: 25 }}>Volume Statistics</div>
                  <Line 
                    data={{
                      labels: this.dateFormatter(),
                      datasets: [{
                        label: 'Volume',
                        data: dashboardDetails.volume_and_quality.volume_list,
                        backgroundColor: 'rgba(68, 179, 7, 0.3)',
                        borderColor: 'rgba(68, 179, 7, 1)'
                      }]
                    }}
                    options={{
                      legend: {
                        position: 'bottom',
                        labels: {
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: 600
                        }
                      },
                      scales: {
                        yAxes: [{
                          gridLines: {
                            color: '#e7e7e7',
                            borderDash: [1, 3],
                          },
                          scaleLabel: {
                            display: true,
                            labelString: 'Volume',
                            fontSize: 14,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          ticks: {
                            fontSize: 12,
                            beginAtZero: true,
                            fontFamily: "'Source Sans Pro', sans-serif"
                          }
                        }],
                        xAxes: [{
                          gridLines: {
                            color: '#fff',
                            borderDash: [1, 3],
                          },
                          scaleLabel: {
                            display: true,
                            labelString: '',
                            fontSize: 14,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                          },
                        }]
                      }
                    }}
                    
                  />
                </div>
                <div class="col-xs-12 col-sm-6">
                  <div style={{ fontSize: 18, fontFamily: 'Source Sans Pro', fontWeight: 600, margin: 25 }}>Quality Statistics</div>
                  <Line 
                    data={{
                      labels: this.dateFormatter(),
                      datasets: [{
                        label: 'Quality',
                        data: dashboardDetails.volume_and_quality.quality_list,
                        backgroundColor: 'rgba(32, 168, 216, 0.3)',
                        borderColor: 'rgba(32, 168, 216, 1)'
                      }]
                    }}
                    options={{
                      legend: {
                        position: 'bottom',
                        labels: {
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: 600
                        }
                      },
                      scales: {
                        yAxes: [{
                          gridLines: {
                            color: '#e7e7e7',
                            borderDash: [1, 3],
                          },
                          scaleLabel: {
                            display: true,
                            labelString: 'Quality',
                            fontSize: 14,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          ticks: {
                            fontSize: 12,
                            beginAtZero: true,
                            fontFamily: "'Source Sans Pro', sans-serif"
                          }
                        }],
                        xAxes: [{
                          gridLines: {
                            color: '#fff',
                            borderDash: [1, 3],
                          },
                          scaleLabel: {
                            display: true,
                            labelString: '',
                            fontSize: 14,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                          },
                        }]
                      }
                    }}
                  />
                </div>
              </div>  
            ) : null
          }
        </div>  
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboardDetails: state.dashboard.dashboardDetails
  };
};

export default connect (mapStateToProps, {
  getDashboardDetails
})(Dashboard);


