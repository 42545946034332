import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import kcLogo from '../images/kc1.png';
import { hashHistory } from 'react-router';
import '../index.css';
import { getKabadiwallaDetails, getDetails } from '../actions';

class Kabadiwalla extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  
  componentDidMount() {
    this.props.getKabadiwallaDetails();
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  CellFormatter(cell, row) {
    return (<div><a target="_blank" href={"https://urbin-api.kabadiwallaconnect.in/public/show_schedule.php?id="+row.id}>Schedule</a></div>);
  }
  
  onClick = (row) => {
    //this.props.getDetails(row);
    //hashHistory.push('/Kabadiwalla/Details');
  }
  
  render() {
    const { kabadiwallaDetails } = this.props;
    var options = {
     onRowClick: this.onClick
    }
    return (
      <div>
        {/* Navbar */}
        <Navbar className="navbarStyle" light expand="md">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">Dashboard</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Urbin">Urbin</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Pickups">Pickups</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Kabadiwalla">Kabadiwalla</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <div className="urbinContainer tableContent">  
          <h2>Kabadiwalla</h2>
          {
            kabadiwallaDetails ? (
              <div>
                {
                  <BootstrapTable data={kabadiwallaDetails} pagination hover multiColumnSearch options={options}>
                    <TableHeaderColumn dataField='id' isKey dataSort width="110" dataAlign='center' >User ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='name' dataSort width="145" dataAlign='center' >Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='shop_name' dataSort width="145" dataAlign='center' >Shop Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='email' width="240" dataAlign='center'>Email</TableHeaderColumn>
                    <TableHeaderColumn dataField='phone' width="200" dataAlign='center'>Mobile Number</TableHeaderColumn>
                    <TableHeaderColumn dataField='id' width="200" dataAlign='center' dataFormat={this.CellFormatter}>Schedule</TableHeaderColumn>

                </BootstrapTable>
              }
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    kabadiwallaDetails: state.kabadiwalla.kabadiwallaDetails
  };
};

export default connect (mapStateToProps, {
  getKabadiwallaDetails, 
  getDetails
})(Kabadiwalla);

