import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import { Link } from 'react-router';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import kcLogo from '../images/kc1.png';
import '../index.css';

class PickupDetails extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  renderColor(status) {
    if(status === 'Completed') {
      return "fillPercentContainerCompleted";
    } else if (status === 'Scheduled') {
      return "fillPercentContainerScheduled"
    } else {
      return "fillPercentContainerPending"
    }
  }
  
  render() {
    const { pickupDetails } = this.props;
    return (
      <div>
        {/* Navbar */}
        <Navbar className="navbarStyle" light expand="md">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">Dashboard</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Urbin">Urbin</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Pickups">Pickups</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Kabadiwalla">Kabadiwalla</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

        {
          this.props.loading ? (
            <div className="spinnerContainer">
              <Loader
                type="TailSpin"
                color="#39A300"
                height="40"
                width="40"
                style={{ }}
              />
            </div>
          ) :

          (<div className="urbinContainer">
            <div style={{ marginTop: 10, paddingBottom: 10 }}>
              <div class="d-flex justify-content-between box" style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10, backgroundColor: '#e7e7e7'}}>
                <div className="orderHeaderStyle">Pickup #{pickupDetails.id}</div>
                <div className={this.renderColor(pickupDetails.status)}>{pickupDetails.status}</div>
              </div>
              <div class="d-flex box" style={{ paddingBottom: 10, paddingLeft: 40, paddingRight: 40 }}>
                <div className="urbinDetailsText"><div className="urbinDetailsHeading">Bin Id</div>{pickupDetails.bin_id}</div>
                <div className="urbinDetailsText"><div className="urbinDetailsHeading">Host Name</div>{pickupDetails.name}</div>
                <div className="urbinDetailsText"><div className="urbinDetailsHeading">Kabadiwalla</div>{pickupDetails.kw_name}</div>
              </div>
              <div class="d-flex box" style={{ paddingBottom: 10, paddingLeft: 40, paddingRight: 40}}>
                <div className="urbinDetailsText"><div className="urbinDetailsHeading">Scheduled On</div>{moment(pickupDetails.scheduled_on).format('MMM Do YYYY')}</div>
                <div className="urbinDetailsText"><div className="urbinDetailsHeading">Collected On</div>{pickupDetails.collected_on ? moment(pickupDetails.collected_on).format('MMM Do YYYY'): '-'}</div>
                <div className="urbinDetailsText"><div className="urbinDetailsHeading">Reported On</div>{pickupDetails.reported_on ? moment(pickupDetails.reported_on).format('MMM Do YYYY'): '-'}</div>
              </div>
              <div class="d-flex box" style={{ paddingBottom: 10, paddingLeft: 40, paddingRight: 40 }}>
                <div className="urbinDetailsText"><div className="urbinDetailsHeading">Volume Collected</div>{pickupDetails.total_weight>0 ? pickupDetails.total_weight:"-"}</div>
                <div className="urbinDetailsText"><div className="urbinDetailsHeading">Pickup Quality</div>{(Math.round((1-(pickupDetails.rejects/pickupDetails.total_weight))*100))?Math.round((1-(pickupDetails.rejects/pickupDetails.total_weight))*100) : '- '}%</div>
              </div>




              <div style={{ marginTop: 30, width: null }} className={pickupDetails.materials.length==0 ? 'hidden' : ''}>
                <div className="orderHeaderStyle" style={{ marginTop: 10, marginBottom: 10 }}>Reported Materials</div>
                <div className="tableContent">
                  <BootstrapTable data={pickupDetails.materials} hover multiColumnSearch>
                    <TableHeaderColumn dataField='material' isKey dataSort width="110" dataAlign='center' >Material Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='quantity' dataSort width="145" dataAlign='center' >Quantity (Kg)</TableHeaderColumn>
                  </BootstrapTable>
                </div>  
              </div>



            </div>         
          </div> ) 
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pickupDetails: state.pickup.pickupDetails,
    loading: state.pickup.loading
  };
};

export default connect (mapStateToProps, {
  
})(PickupDetails);
