const INITIAL_STATE = {
  loading: false,
  pickups: '',
  selectedPickup: '',
  binId: '',
  pickupScheduled: false
}

export default (state = INITIAL_STATE, action) => {
  console.log(action);  
  switch (action.type) {
    case 'PICKUP':
      return { ...state, loading: true };
    case 'PICKUP_SUCCESS':
      return { ...state, pickups: action.payload, loading: false };
    case 'PICKUP_FAIL':
      return { ...state, loading: false };
      
    case 'PICKUP_FULL':
      return { ...state, loading: true };
    case 'PICKUP_FULL_SUCCESS':
      return { ...state, pickupDetails: action.payload, loading: false };
    case 'PICKUP_FULL_FAIL':
      return { ...state, loading: false };
    
    case 'SELECTED_PICKUP_DETAILS':
      return { ...state, selectedPickup: action.payload };
      
    case 'BIN_ID':
      return { ...state, binId: action.payload };
    
    case 'SCHEDULE_PICKUP':
      return { ...state, loading: true };
    case 'SCHEDULE_PICKUP_SUCCESS':
      return { ...state, loading: false, binId: '' };
    case 'SCHEDULE_PICKUP_FAIL':
      return { ...state, loading: false };
    
    default:
      return state;
  }
};
